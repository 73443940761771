.cursos{
  .container{
    margin-bottom: 20px;
  }
  .tabela{
    p{
        text-align: center;
        text-transform: uppercase;
        color: $lodo;
        padding-top: 50px;
        width: 100%;
        line-height: 18px;
        &:nth-child(2){
          padding: 0 50px;
        }
    }
    .aviso-rodape{
      padding: 0 50px;
    }
    table{
      margin-bottom:0;
      .listado{
        &:first-child{
          td{
            background: $lodo;
            color: #fff !important;
            text-align: center;
            font-family: Arial;
            font-weight: bold;
          }
        }//&:first-child
        td{
          border: 5px solid #fff;
          background: #f5f5f5;
          font-family: Arial;
          font-weight: bold;
          text-align: center;
          color: $cinza;
          display:table-cell;
          vertical-align:middle;
          &:hover{
            cursor: pointer;
          }
          &:first-child{
            text-align: left;
            color: $azul;
          }
          a{
              margin-right: 10px;
              position: relative;
              top: -4px;
            }
        }//td
        &.active{
          td{
            background: $amarelo !important;
          }
        }
      }

      .inscritos{
        td{
          text-align: center !important;
          color:$cinza !important;
        }
      }
      .descricao{
        background: $azul;
        color: #fff;
        margin: 0 5px 70px;
        padding: 30px 110px;
        display: none;  
      }
    }//table
    .btn-inscrever{
      background: $laranja;
      color: #fff;
      text-transform: uppercase;
      padding: 5px 50px;
      border-radius: 30px;
      margin:20px 0;
    }
  }//tabela
  .table-responsive{
    @media(min-width:768px){
      display: table;
    }
  }
}