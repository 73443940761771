header{
  background: url(../img/topo-site.png)no-repeat top center / 100%;
  width: 100%;
  display: table;
  margin: 0 auto;
  @media(max-width:960px){
    background: url(../img/topo-site.png) no-repeat top center/230%;
  }
  .container{
    position:relative;
    margin-bottom: 20px;
    .logo-motiva{
      display: block;
      width: 320px;
      margin: 20% auto 0;
      position: relative;
      @media(max-width:768px){
        margin: 25% auto 5%;
      }
    }
    .menu{
      list-style: none;
      padding: 0;
      @media (min-width:768px){
        right: 0;
        position: absolute;
        top: 65%;
      }
      li{
        a{
          color: $azul;
          text-transform: uppercase;
        }
        &.active{
          a{
            color: $laranja;
          }
          &:before{
            content: "";
            position: relative;
            right: -15px;
            top: 7px;
            float: right;
            height: 5px;
            width: 5px;
            display: block;
            border-style: solid;
            border-width: 5px 9px 5px 0;
            border-color: transparent $laranja transparent transparent;
          }
        }
      }
    }
  }
}