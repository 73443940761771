.banner-flutuante {
    position: absolute;
    background: #fff;
    top: 15%;
    border: 2px solid #1e4d7c;
    border-radius: 12px;
    padding-bottom: 30px;
    z-index: 9;
    @media(min-width:768px){
        left: 10%;
    }
    @media(max-width:768px){
        left: 0;
    }
    div{
        position: relative;
        span{
            position: absolute;
            right: 0;
            background: #204c7a;
            border-radius: 100%;
            padding: 5px 10px;
            color: #fff;
            top: 10px;
            &:hover{
                cursor:pointer;
            }
        }
        img {
            display: block;
            margin: 0 auto;
        }
        p {
            &:nth-child(3) {
                font-size: 24px;
                font-weight: 600;
                text-transform: uppercase;
                position: relative;
                padding-bottom: 25px;
                margin-bottom: 30px;
                margin-top: 30px;
                font-weight: bold;
                text-align: center;
                &:after {
                    content: "";
                    display: block;
                    width: 90px;
                    height: 5px;
                    background: #1e5486;
                    margin: 20px auto 0;
                }
            }
        }
        .btn-acesso {
            background: $lodo;
            color: #fff;
            text-transform: uppercase;
            padding: 5px 0;
            border-radius: 50px;
            margin: 0 auto;
            display: block;
            width: 120px;
            text-align: center;
        }
    }
}
.active{
    display: block !important;
}

.modalEmail{
    height: 100vh;
    width: 100%; 
    position: fixed;
    top: 0;
    left: 0; 
    z-index: 999999999;
    display: none;
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.7);
        z-index: 998; 
    }
    .modal-content-email{
        width: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        z-index: 999;
        input{
            width: 100%;
            height: 40px;
            background: #fff;
            color: #000;
            margin: 5px 0;
            border-radius: 5px;
            border: 0;
           
        }
        input[type="submit"]{
            cursor: pointer;
        }
    }
}

.none{
    display: none !important;
}