/*!
 * Bootstrap Grid v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "source/functions";
@import "source/variables";

//
// Grid mixins
//

@import "source/mixins/breakpoints";
@import "source/mixins/grid-framework";
@import "source/mixins/grid";

@import "source/grid";
@import "source/utilities/flex";
