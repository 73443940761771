.form-login{
  background: #f5eb55;
  border-radius: 8px;
  margin: 5% 0 8%;

  form{
    padding:50px 0 40px;
    label{
      text-align: center;
      width: 100%;
      font-family: Arial;
      font-weight: bold;
      color: #21609b;
      letter-spacing: 3px;
    }
    input{
      border-radius: 0;
      border: none;
      box-shadow: inset 1px 1px 5px #cac7c7;
      padding: 10px 5px;
      outline-style: none;
    }
    button{
      border-radius: 30px;
      padding: 5px 60px;
      text-transform: uppercase;
      background: #43cb83;
      border: none;
      margin: 0 auto;
      display: block;
    }
  }
}

.nao-inscrito{
  p{
    text-align: center;
    font-family: Arial;
    font-weight: bold;
    padding: 0 20px;
    &:first-child{
      color: $vermelho;
      padding-top: 50px;
    }
    &:nth-child(2){
      color: $lodo;
    }
  }
  .btn-inscrever{
      background: $laranja;
      color: #fff;
      text-transform: uppercase;
      padding: 5px 50px;
      border-radius: 30px;
      margin:50px auto;
      display: block;
      width: 190px;
    }
}

.update{
  background: $laranja;
  label{
    color: $cinza !important;
    text-align: left !important;
    span{
      color: #fff;
    }
  }
  .telefone{
    text-align: center !important;
  }
  #telefone{
    text-align: center;
  }
  button{
    background: $amarelo !important;
    color: $cinza !important;
  }
}

.title{
  font-size: 32px;
}