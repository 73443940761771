//Custom variables

// Descriptive color variables

// Functional color variables

// Font stacks

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';

$azul : #1d5486;
$verde : #43cb82;
$amarelo : #f5eb54;
$laranja : #ff9539;
$vermelho : #ab2447;
$cinza : #4d4d4d;
$lodo : #64b0aa;
